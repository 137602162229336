<template>
  <v-list-item @click="selectWidget">
    <v-list-item-content>
      <v-list-item-title>
        {{ widget.label || widget.title }}
      </v-list-item-title>
      <v-list-item-subtitle class="font-italic">
        {{ $t('lastUpdated') }}: {{ formatChangedTimestamp }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "WidgetListItem",
  props: {
    widget: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    selectWidget() {
      this.$emit('selectWidget', this.widget)
    }
  },
  computed: {
    formatChangedTimestamp() {
      if(this.widget.changed) {
        return this.$moment(this.widget.changed).format('DD MM YYYY HH:mm:ss')
      }
      return 'N/A'
    }
  }
}
</script>

<style scoped>

</style>