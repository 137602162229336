<template>
  <ExtendedView :tabs="tabs">
    <template v-slot:list="{ item }">
      <WidgetList
        :fixed-filters="fixedFilters(item.key)"
        :load-items-callback="getStoreWidgets"
        :call-to-actions="callToActions"
        @selectWidget="selectWidget"
      />
    </template>
    <template v-slot:profile>
      <WidgetProfile
        v-if="selectedWidget"
        :widget="selectedWidget"
        :update-call-back="showEditWithPreview"
        :change-active-status-callback="updateWidget"
        widget-type="store"
        :preview-url="'https://store.salescloud.is/?uuid=' + selectedWidget.uuid"
      />
    </template>
  </ExtendedView>
</template>

<script>
import ExtendedView from "@/components/common/ExtendedView"
import WidgetList from "@/components/widget/WidgetList"
import WidgetProfile from "@/components/widget/WidgetProfile"

export default {
  name: "StoreWidgets",
  components: {
    WidgetProfile,
    WidgetList,
    ExtendedView
  },
  data() {
    return {
      storeWidgets: [],
      selectedWidget: null
    }
  },
  computed: {
    organization() {
      return this.$store.state.organization
    },
    activeCount() {
		return this.storeWidgets?.filter(widget => widget?.active).length ?? 0
    },
    inactiveCount() {
		return this.storeWidgets?.filter(widget => !widget?.active).length ?? 0
    },
    callToActions() {
      return [
        {
          title: this.$t('add'),
          callback: this.addNewStoreWidget
        }
      ]
    },
    tabs() {
      return [
        {
          title: 'Active',
          key: 'active',
          badge: {
            content: this.activeCount
          }
        },
        {
          title: 'Inactive',
          key: 'inactive',
          badge: {
            content: this.inactiveCount
          }
        }
      ]
    },
    selectedAppBarTab() {
      return this.$store.state.selectedAppBarTab
    }
  },
  methods: {
    selectWidget(widget) {
      this.selectedWidget = widget
    },
    fixedFilters(key) {
      switch (key) {
        case 'active':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: true,
              operator: 'equals'
            }
          ]
        case 'inactive':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: false,
              operator: 'equals'
            }
          ]
        default:
          return []
      }
    },
    addNewStoreWidget() {
      this.$store.commit('updateDataToMutate', {
        objectToMutate: {
          organization: this.organization.uuid,
          label: null,
          channel: null,
          locations: null,
          categories: null,
          paymentMethodInstances: null,
          deliveryMethodInstances: null,
          darkTheme: false,
          defaultLanguage: null,
          showVariationPrices: null,
          backgroundColor: null,
          primaryColor: null,
          accentColor: null,
          fontFamily: null,
          showLocationDateSelection: true,
          showLocationTimeSlotSelection: true,
          locationDateSelectionDays: 7,
          locationDateSelectionSpecificDays: null,
          selectLocationFirst: false,
          metaPixelCode: null,
          allowedLanguages: null,
          applePayEnabled: false,
          orderFulfillments: [ 'TakeAway' ]
        },
        formComponent: 'components/widget/StoreWidgetForm',
        saveCallback: this.createStoreWidget,
        previewUrl: 'https://store.salescloud.is',
        title: this.$t('editWithPreview')
      })
    },
    createStoreWidget(newStoreWidget) {
      this.$store.dispatch('createStoreWidget', newStoreWidget).then(result => {
        if(result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('createdWidgetSuccess')
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: this.$t('couldNotCreateWidget')
          })
        }
      }).catch(() => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotCreateWidget')
        })
      })
    },
    getStoreWidgets() {
      return this.$store.dispatch('getStoreWidgets').then(result => {
        this.storeWidgets = result
        this.$store.commit('updateAppBarTabs', this.tabs)
        return result
      })
    },
    showEditWithPreview(profile) {
      return this.$store.commit('updateDataToMutate', {
        objectToMutate: profile,
        formComponent: 'components/widget/StoreWidgetForm',
        saveCallback: this.updateWidget,
        previewUrl: 'https://store.salescloud.is',
        title: this.$t('editWithPreview')
      })
    },
    updateWidget(newValues) {
      this.updating = true
      return this.$store.dispatch('updateStoreWidget', newValues).then(result => {
        if(result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('updatedWidgetSuccess')
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: this.$t('couldNotUpdateWidget')
          })
        }
        return result
      }).catch(() => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotUpdateWidget')
        })
      }).finally(() => {
        this.editMode = false
        this.updating = false
      })
    },
  },
  watch: {
    storeWidgets(value) {
      if(typeof this.selectedWidget !== 'undefined' && this.selectedWidget !== null) {
        const widgetsIndex = value.findIndex(w => w && w.uuid === this.selectedWidget.uuid)
        if(widgetsIndex >= 0) {
          this.selectedWidget = value[widgetsIndex]
        }
      }
    },
    selectedAppBarTab(current, previous) {
      if(current !== previous) {
        this.selectedWidget = null
      }
    }
  },
  created() {
    this.getStoreWidgets()
    this.$store.dispatch('getDeliveryServices')
    this.$store.dispatch('getPaymentMethodInstances')
    this.$store.dispatch('getOrderFulfillmentOptions')

    this.$store.commit('updateAppBarTabs', this.tabs)
    if(Array.isArray(this.tabs) && this.tabs.length > 0) {
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    }

    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
    this.$store.commit('setMinimizeSidebar', false)
  },
  updated() {
    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
    this.$store.commit('setMinimizeSidebar', false)
  },
  destroyed() {
    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', false)
    this.$store.commit('setMinimizeSidebar', true)
  }
}
</script>
