<template>
  <ActionList
    :load-items-callback="loadItemsCallback"
    :filters="filters"
    :call-to-actions="callToActions"
    :fixed-filters="fixedFilters"
  >
    <template v-slot:item="{ item, index }">
      <WidgetListItem
        :key="index"
        :widget="item"
        @selectWidget="selectWidget(item)"
      />
    </template>
  </ActionList>
</template>

<script>
import ActionList from "@/components/common/list/ActionList"
import WidgetListItem from "@/components/widget/WidgetListItem"

export default {
  name: "WidgetList",
  components: {
    WidgetListItem,
    ActionList
  },
  props: {
    loadItemsCallback: {
      type: Function,
      default: () => null
    },
    callToActions: {
      type: Array,
      default: () => []
    },
    fixedFilters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filters() {
      return [
        {
          key: 'active',
          label: this.$t('active'),
          type: 'boolean',
          value: null,
          operator: 'equals'
        }
      ]
    }
  },
  methods: {
    selectWidget(widget) {
      this.$emit('selectWidget', widget)
    }
  }
}
</script>

<style scoped>

</style>