<template>
  <div>
    <v-card>
      <v-card-title class="align-self-center">
        <span>
          {{ widget.label || widget.title }}
        </span>
        <v-spacer />
        <v-switch
          hide-details
          inset
          :loading="settingActive"
          :disabled="updating || settingActive"
          class="mt-0 mb-1 pr-5"
          :class="widget.active ? 'switch-on' : 'switch-off'"
          :input-value="widget.active"
          @change="updateActive"
          :label="widget.active ? $t('active') : $t('inactive')"
        />
        <v-btn
          v-if="editMode === false"
          depressed
          class="text-none"
          @click="showEditWithPreview"
        >
          {{ $t('editWithPreview') }}
        </v-btn>
        <v-btn
          v-if="widgetLink"
          :href="widgetLink"
          target="_blank"
          depressed
          class="text-none ml-2"
        >
          {{ $t('viewOnline') }}
        </v-btn><v-btn
          target="_blank"
          depressed
          class="text-none ml-2"
          @click="copyUuid"
        >
          {{ $t('copy') }} UUID
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="row">
          <div class="col-12 col-md-8">
            <div
              v-if="widgetType === 'store'"
              style="font-size: 16px; font-weight: 700; padding-bottom: 4px;"
            >
              {{ $t('injectingHTMLCodeInformation') }}
            </div>
            <div v-if="websiteHTMLCode">
              <div class="pb-1 text--primary">
                {{ $t('basicHtmlSetup') }}
              </div>
              <div>
                <code
                  style="width: 100%; display: block; white-space: pre; overflow-x: auto;"
                  v-text="websiteHTMLCode"
                />
              </div>
            </div>
            <div v-if="websiteScriptCode">
              <div class="py-1 text--primary">
                {{ $t('basicScriptSetup') }}
              </div>
              <div>
                <code
                  style="width: 100%; display: block; white-space: pre; overflow-x: auto;"
                  v-text="websiteScriptCode"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="text-end pb-3">
              <div class="caption">
                {{ $t('Setup Code') }}
              </div>
              <div class="text-h4 black--text">
                {{ widget.shortId }}
              </div>
            </div>
            <v-img
              :src="qrCode"
              max-width="130px"
              max-height="130px"
              min-width="130px"
              min-height="130px"
              class="ml-auto"
            />
            <div class="text-end pt-1">
              {{ widget.uuid }}
            </div>
          </div>
        </div>
      </v-card-text>
      <v-tabs v-model="selectedTab">
        <template v-for="tab in tabs">
          <v-tab
            :key="tab.key"
            :href="'#' + tab.key"
          >
            {{ tab.label }}
          </v-tab>
        </template>
      </v-tabs>
    </v-card>
    <v-tabs-items
      class="gray lighten-3 transparent"
      v-model="selectedTab"
    >
      <template v-for="tab in tabs">
        <v-tab-item
          :key="tab.key"
          :value="tab.key"
          class="transparent"
        >
          <iframe
            v-if="tab.key === 'preview' && previewUrl"
            :src="previewUrl"
            style="width: 100%; height: 100vh; border: 0;"
          />
        </v-tab-item>
      </template>
    </v-tabs-items>
  </div>
</template>

<script>


export default {
  name: "WidgetProfile",
  components: {},
  props: {
    widget: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Array,
      default: () => []
    },
    updateCallBack: {
      type: Function,
      default: () => {}
    },
    changeActiveStatusCallback: {
      type: Function,
      default: () => {}
    },
    widgetType: {
      type: String,
      default: null
    },
    previewUrl: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      selectedTab: 'preview',
      settingActive: false,
      updating: false,
      editMode: false,
      profile: null,
      uuidAsQrCodeImage: null,
      fieldsWithValues: []
    }
  },
  computed: {
    widgetLink() {
      if(typeof this.widget === 'undefined' || this.widget === null) {
        return ''
      }

      if(typeof this.previewUrl !== 'undefined' && this.previewUrl !== null && this.previewUrl !== '') {
        return this.previewUrl
      }

      switch (this.widgetType) {
        case 'store':
          return 'https://store.salescloud.is?uuid=' + this.widget.uuid
        case 'schedule':
          return 'https://schedule.salescloud.is?uuid=' + this.widget.uuid
        case 'kiosk':
          return 'https://kiosk.salescloud.is?uuid=' + this.widget.uuid
        case 'order-status-display':
          return 'https://order-status.salescloud.is?uuid=' + this.widget.uuid
        case 'process-display-system':
          return 'https://order-display.salescloud.is?uuid=' + this.widget.uuid
        default:
          return ''
      }
    },
    tabs() {
      return [
        {
          label: this.$t('preview'),
          key: 'preview'
        }
      ]
    },
    websiteHTMLCode() {
      switch (this.widgetType) {
        case 'store':
          return '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900">\n' +
            '<link href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css" rel="stylesheet">\n' +
            '<link rel="stylesheet" type="text/css" href="https://store.salescloud.is/assets/style.css?v=5">\n' +
            '<div id="salescloud-widget-frame-wrapper"></div>'
        default:
          return ''
      }
    },
    websiteScriptCode() {
      switch (this.widgetType) {
        case 'store':
          return '<script>\n' +
            ' window["sc-store"] = { uuid: "' + this.widget.uuid + '" }\n' +
            ' // Inject the widget\n' +
            ' let frameWrapper = document.querySelector("#salescloud-widget-frame-wrapper")\n' +
            ' let script = document.createElement("script")\n' +
            ' script.type = "module"\n' +
            ' script.src = "https://store.salescloud.is/assets/index.js?time=" + Date.now()\n' +
            ' \n' +
            ' frameWrapper.innerHTML = "<div id=\'app\'></div>";\n' +
            ' document.body.appendChild(script);\n' +
            '<\/script>'
        default:
          return ''
      }
    },
    qrCode() {
      if(typeof this.widget !== 'undefined' && this.widget !== null) {
        if(typeof this.widget.uuid !== 'undefined' && this.widget.uuid !== null && this.widget.uuid !== '') {
          return `https://europe-west1-proton-mainframe.cloudfunctions.net/generateQRCode?qrContent=${this.widget.uuid}`
        }
      }
      return ''
    }
  },
  methods: {
		copyUuid() {
			navigator.clipboard.writeText(this.widget.uuid)
		},
    showEditWithPreview() {
      this.updateCallBack(this.profile)
    },
    updateActive() {
      this.settingActive = true
      const updateValues = {
        uuid: this.widget.uuid,
        organization: this.widget.organization,
        active: !this.widget.active
      }

      this.changeActiveStatusCallback(updateValues).finally(() => {
        this.settingActive = false
      })
    }
  },
  watch: {
    editMode(current, previous) {
      if(current === false && previous === true) {
        if(typeof this.widget !== 'undefined' && this.widget !== null) {
          try {
            this.profile = JSON.parse(JSON.stringify(this.widget))
          } catch (e) {
            // nothing happens
          }
        }
      }
    },
    widget(current, previous) {
      if(current !== previous) {
        try {
          if(current) {
            this.profile = JSON.parse(JSON.stringify(current))
          }
        } catch (e) {
          // nothing happens
        }
      }
    },
    profile(value) {
      const newFields = []
      for(const index in this.fields) {
        if(index && this.fields[index]) {
          const field = this.fields[index]
          field.value = value[field.key]
          newFields.push(field)
        }
      }
      this.fieldsWithValues = newFields
    }
  },
  mounted() {
    if(typeof this.widget !== 'undefined' && this.widget !== null) {
      try {
        this.profile = JSON.parse(JSON.stringify(this.widget))
      } catch (e) {
        // nothing happens
      }
    }
  }
}
</script>

<style scoped>
.switch-off >>> .v-input--switch__track {
  color: red;
}
.switch-on >>> .v-input--switch__track {
  color: green;
}
.switch-on >>> .v-input--switch__thumb {
  color: #FFFFFF!important;
}
</style>
